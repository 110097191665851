<template>
  <Card>
    <div class="header">
      <div class="header-title">
        <div class="round"></div>
        用户购买力排行
      </div>
      <div>
        <div
          v-for="item in options"
          :key="item.value"
          class="header-option"
          :class="{ 'header-option-active': date === item.value }"
          @click="optionChange(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>

    <div>
      <div class="list">
        <div class="list-title" style="text-align: center">排名</div>
        <div class="list-title">姓名</div>
        <div class="list-title">消费金额(元)</div>
      </div>
      <div v-for="(item, index) in list" class="list" :key="item.id">
        <div class="list-item" style="text-align: center">{{ index + 1 }}</div>
        <div class="list-item" style="padding-right: 50px">
          {{ item.nickName }}
        </div>
        <div class="list-item" style="color: #505162">
          {{ _pennyToYuan(item.orderAmount) }}
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/card'
import * as homeService from '../../api/home'
import { getDateRange } from './helper'

export default {
  components: {
    Card
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      options: [
        { label: '今日', value: 0 },
        { label: '昨日', value: 1 },
        { label: '近7日', value: 7 },
        { label: '近30日', value: 30 }
      ],
      date: 0,
      list: []
    }
  },
  methods: {
    optionChange (value) {
      this.date = value
      this.fetchData(value)
    },
    async fetchData (val = this.date) {
      const res = await homeService.consume(getDateRange(val))
      this.list = res
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-columns: 150px auto;
  justify-content: space-between;
  border-bottom: 1px solid #d9e0f0;
  line-height: 40px;

  &-title {
    color: #7d7e8e;
    font-size: 18px;
  }

  &-option {
    cursor: pointer;
    margin: auto 14px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #acadb7;

    &-active {
      color: #7d7e8e;
      border-bottom: 2px solid #1d90fbff;
    }
  }
}

.list {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 100px auto 150px;

  &-title {
    text-align: left;
    background: #f2f2f2;
    color: #7d7e8e;
    font-size: 14px;
    line-height: 30px;
  }
  &-item {
    background-color: #ffffff;
    text-align: left;
    font-size: 14px;
    color: #acadb7;
  }
}

.round {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: #44bff2;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 3px;
}
</style>
