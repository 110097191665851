import axios from './axios'

export const list = params => axios.get('/management/user/list', { params })
export const disabled = id => axios.put(`/management/user/${id}/disable`)
export const enable = id => axios.put(`/management/user/${id}/able`)
export const update = (form) => axios.put('/management/user', form)
export const detail = id => axios.get(`/management/user/${id}`)
export const deleteById = id => axios.delete(`/management/user/${id}`)
export const create = form => axios.post('/management/user', form)
export const userInfo = () => axios.get('/management/user/info')
