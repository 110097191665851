<template>
  <div class="digest">
    <div class="digest-item">
      <div class="digest-item-icon"><i class="iconfont">&#xe639;</i></div>
      <div>
        <div class="digest-item-value">{{data.todayCount}}</div>
        <div class="digest-item-name">今日订单</div>
      </div>
    </div>
    <div class="digest-item pointer" @click="$router.push('/order?status=1')">
      <div class="digest-item-icon"><i class="iconfont">&#xe638;</i></div>
      <div>
        <div class="digest-item-value">{{data.needSendCount}}</div>
        <div class="digest-item-name">待发货订单</div>
      </div>
    </div>
    <div class="digest-item pointer" @click="$router.push('/order?status=0')">
      <div class="digest-item-icon"><i class="iconfont">&#xe637;</i></div>
      <div>
        <div class="digest-item-value">{{data.needPayCount}}</div>
        <div class="digest-item-name">待付款订单</div>
      </div>
    </div>
    <div class="digest-item pointer" @click="$router.push('/order?status=5')">
      <div class="digest-item-icon"><i class="iconfont">&#xe635;</i></div>
      <div>
        <div class="digest-item-value">{{data.closedCount}}</div>
        <div class="digest-item-name">已取消订单</div>
      </div>
    </div>
    <div class="digest-item">
      <div class="digest-item-icon"><i class="iconfont">&#xe636;</i></div>
      <div>
        <div class="digest-item-value">{{data.sellOutGoodsCount}}</div>
        <div class="digest-item-name">已售罄商品</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as homeService from '../../api/home'

export default {
  created () {
    this.fetchData()
  },
  data () {
    return {
      data: {
        todayCount: 0,
        needSendCount: 0,
        needPayCount: 0,
        closedCount: 0,
        sellOutGoodsCount: 0
      }
    }
  },
  methods: {
    async fetchData () {
      const res = await homeService.statistics()
      this.data = res
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/layout.scss';
.digest {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid $borderColor;
  height: 150px;
  background-color: $cardColor;

  &-item {
    border-right: 1px solid $borderColor;
    display: grid;
    grid-template-columns: 40px auto;
    text-align: left;
    justify-content: space-evenly;
    align-items: center;

    &-icon {
      i {
        font-size: 40px;
        width: 40px;
        height: 40px;
      }
    }

    &-value {
      color: #505162FF;
      font-size: 24px;
      line-height: 31px;
    }

    &-name {
      color: #ACADB7FF;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
