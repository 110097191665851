import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null
  },
  getters: {
    userInfo: (state, getters) => {
      return state.userInfo
    }
  },
  mutations: {
    setUserIfo (state, data) {
      state.userInfo = data
    }
  },
  actions: {
    savePath ({ commit }, payload) {
      commit('savePath', payload)
    }
  },
  modules: {
  }
})
