import axios from 'axios'
import * as R from 'ramda'
import qs from 'qs'
import { baseURL } from '@/config'

const AJH = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// 请求拦截器
AJH.interceptors.request.use(
  (config) => {
    if (config.url !== '/authenticate') {
      config.headers.Authorization = `Bearer ${sessionStorage.getItem('token') || ''}`
    }
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        Object.keys(params).forEach(item => {
          if (params[item] === '' || params[item] === null || params[item] === undefined) delete params[item]
        })
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  error => Promise.reject(error)
)

AJH.interceptors.response.use((response) => {
  if (R.contains('export', response.config.url)) {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', '订单.xls')
    document.body.appendChild(link)
    link.click()
    return Promise.resolve(undefined)
  }
  if (response.data.expressErrorMsg) {
    return response.data
  }
  return response.data.body
}, (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 401: window.location.href = '/login.html'; break
      case 404: window.location.href = '/404.html'; break
      default: break
    }
  }

  return Promise.reject(error.response.data)
})

export const login = (params) => AJH.post('/management/authenticate', params).then((resp) => resp)

export default AJH
