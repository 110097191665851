<template>
  <div class="content" :style="{ 'grid-template-columns': `${labelWidth} auto`}">
    <div style="text-align: right;padding-top: 5px;">
      <span v-if="asterisk" style="color: red">*</span>
      {{ label }}
    </div>
    <div class="payload">
      <slot></slot>
      <small class="errorMsg">{{ hasError ? errorMessage: '' }}</small>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    hasError () {
      return !this.validation.valid
    },
    errorMessage () {
      return this.validation.message
    }
  },
  props: {
    label: String,
    labelWidth: {
      type: String,
      default: '80px'
    },
    validation: {
      type: Object,
      default () {
        return {
          valid: true
        }
      }
    },
    asterisk: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  display: grid;
  align-items: top;
}

.payload {
  text-align: left;
  display: grid;
  grid-template-rows: 1fr 20px;
}

.errorMsg {
  display: block;
  color: #f06c73;
  height: 20px;
}
.asterisk {
  position: absolute;
  right: -7px;
  top: -1px;
  color: #f06c73;
  font-size: 16px;
  pointer-events: none;
}
</style>
