<template>
  <div id="app">
    <Sidebar class="sidebar"></Sidebar>
    <div class="content">
      <Header class="header" />
      <div class="main">
        <router-view />
      </div>
      <div class="footer">
        <div style="margin:0 auto;font-size: 14px;line-height:20px;color:#939393;">
          <img src="@/assets/image/record.png" style="vertical-align: top;" />
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33048302000728"
            style="margin-left: 2px;"
          >
            浙公网安备 33048302000728号
          </a>
          <a target="_blank" href="http://beian.miit.gov.cn/" style="margin-left: 20px;">
            浙ICP备2021021084号
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from './components/Sidebar.vue'
import Header from './components/Header/index.vue'

export default {
  components: {
    Sidebar,
    Header
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: red;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $sidebarWidth;
  height: 100vh;
  background-color: $sidebarBgColor;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  position: fixed;
  top: 0;
  left: $sidebarWidth;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-rows: $headerHeight auto;
  overflow: auto;
  background-color: $bodyBgColor;
}

.header {
  background-color: $headerBgColor;
}

.main {
  padding: 20px;
}

.footer {
  height: 40px;
  line-height: 40px;
  a {
    text-decoration: none;
  }
}
</style>
