import moment from 'moment'

const Day = 24 * 60 * 60 * 1000

const fmt = t => moment(t).format('YYYY-MM-DD')

export const getDateRange = (day = 0) => {
  const now = Date.now()
  let end = Date.now()
  if (day === 1) {
    end -= Day
  }
  return {
    startDate: fmt(now - day * Day),
    endDate: fmt(end)
  }
}
