<template>
  <div class="container">
    <Digest />
    <Analysis />
    <div class="rankList">
      <SaleRankList />
      <PurchaseRankList />
    </div>
    <CommissionRankList />
  </div>
</template>

<script>
import Digest from './digest'
import Analysis from './analysis'
import SaleRankList from './saleRankList'
import PurchaseRankList from './purchaseRankList'
import CommissionRankList from './commissionRankList'
export default {
  components: {
    Digest,
    Analysis,
    SaleRankList,
    PurchaseRankList,
    CommissionRankList
  }
}

</script>

<style scoped>
.rankList {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

</style>
