<template>
  <div class="container">
    <div class="container-logo">
      <img src="../assets/logo.png">
    </div>
    <el-menu :default-active="curPath">
      <ul class="sidebar">
        <li
          v-for="item in realMenus"
          :key="item.name"
          class="sidebar-item"
        >
          <el-submenu :index="item.name" v-if="item.children && item.children.length > 0">
            <template slot="title">
              <i class="iconfont sidebar-item-icon" v-html="item.icon" />
              <span class="sidebar-item-name">{{ item.name }}</span>
            </template>
            <el-menu-item
              v-for="sub in item.children"
              :key="sub.name"
              :index="sub.path"
              class="sidebar-subitem"
              @click="clickSubMenu(item, sub)"
            >
              {{ sub.name }}
            </el-menu-item>
          </el-submenu>

          <el-menu-item v-else :index="item.path" @click="clickMenu(item)">
            <i class="iconfont sidebar-item-icon" v-html="item.icon" />
            <span class="sidebar-item-name">{{ item.name }}</span>
          </el-menu-item>
        </li>
      </ul>
    </el-menu>
    <!-- <div
      v-for="item in menus"
      :key="item.name"
      class="sidebar-item"
    >
      <i class="iconfont">&#xe604;</i>
      {{ item.name }}
    </div> -->
  </div>
</template>

<script>
import { hasInfo } from '@/utils/tools.js'

export default {
  data () {
    return {
      menus: [
        {
          name: '首页',
          icon: '&#xe640;',
          path: '/',
          permission: ['GLOBAL_INDEX']
        },
        {
          name: '小程序设置',
          icon: '&#xe63d;',
          permission: ['GLOBAL_APP'],
          children: [
            { name: '首页设置', path: '/applet', permission: ['GLOBAL_APP_INDEX_SETTING'] }
          ]
        },
        {
          name: '订单管理',
          icon: '&#xe63b;',
          permission: ['GLOBAL_ORDER'],
          children: [
            { name: '普通订单', path: '/order', permission: ['GLOBAL_ORDER_NORMAL'] },
            { name: '拼团订单', path: '/bulkOrder', permission: ['GLOBAL_ORDER_GROUP'] }
          ]
        },
        {
          name: '商品管理',
          icon: '&#xe63c;',
          permission: ['GLOBAL_GOODS'],
          children: [
            { name: '商品列表', path: '/product', permission: ['GLOBAL_GOODS_LIST'] },
            { name: '商品类目', path: '/category', permission: ['GLOBAL_GOODS_CATEGORY'] },
            { name: '商品板块', path: '/plate', permission: ['GLOBAL_GOODS_PLATE'] },
            { name: '规格管理', path: '/spec', permission: ['GLOBAL_GOODS_SPEC'] },
            { name: '商品评价', path: '/comment', permission: ['GLOBAL_GOODS_SPEC'] }
          ]
        },
        {
          name: '客户管理',
          icon: '&#xe63a;',
          permission: ['GLOBAL_CUSTOMER'],
          children: [
            { name: '客户列表', path: '/member', permission: ['GLOBAL_CUSTOMER_LIST'] },
            { name: '提现管理', path: '/withdraw', permission: ['GLOBAL_CUSTOMER_WITHDRAW'] },
            { name: '客户服务', path: '/service', permission: ['GLOBAL_CUSTOMER_SERVICE'] },
            { name: '意见反馈', path: '/feedback', permission: ['GLOBAL_CUSTOMER_ADVICE'] }
          ]
        },
        {
          name: '系统管理',
          icon: '&#xe63e;',
          permission: ['GLOBAL_SETTING'],
          children: [
            { name: '基础设置', path: '/basicConfig', permission: ['GLOBAL_SETTING_BASE'] },
            { name: '用户管理', path: '/user', permission: ['GLOBAL_SETTING_USER'] },
            { name: '角色管理', path: '/role', permission: ['GLOBAL_SETTING_ROLE'] },
            { name: '运费模版', path: '/freightTemplates', permission: ['GLOBAL_SETTING_FREIGHT'] },
            { name: '快递设置', path: '/express', permission: ['GLOBAL_SETTING_EXPRESS'] },
            { name: '仓库设置', path: '/warehouse', permission: ['GLOBAL_SETTING_WAREHOUSE'] },
            { name: '热门问题设置', path: '/hot', permission: ['GLOBAL_SETTING_QUESTION'] }
          ]
        },
        {
          name: '数据统计',
          icon: '&#xe63f;',
          permission: ['GLOBAL_ANALYSE'],
          children: [
            { name: '商品分析', path: '/good/statistics', permission: ['GLOBAL_ANALYSE_GOODS'] },
            { name: '订单分析', path: '/order/statistics', permission: ['GLOBAL_ANALYSE_ORDER'] },
            { name: '用户分析', path: '/user/statistics', permission: ['GLOBAL_ANALYSE_CUSTOMER'] },
            { name: '分销分析', path: '/sale/statistics', permission: ['GLOBAL_ANALYSE_PARTNER'] },
            { name: '金牌合伙人分析', path: '/member/statistics', permission: ['GLOBAL_ANALYSE_PARTNER_VIP'] }
          ]
        }
      ],
      realMenus: [],
      curPath: '/'
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  watch: {
    userInfo: {
      handler () {
        this.computeRealMenus()
      },
      deep: true
    }
  },
  mounted () {
    this.computeRealMenus()
  },
  methods: {
    clickMenu (item) {
      this.$router.push(item.path)
    },
    clickSubMenu (item, subItem) {
      this.$router.push(subItem.path)
    },
    computeRealMenus () {
      if (!this.userInfo || !this.userInfo.permissionCodes) return
      const userPermission = this.userInfo.permissionCodes
      this.realMenus = this.menus.reduce((pre, cur) => {
        if (hasInfo(userPermission, cur.permission)) {
          if (cur.children && Array.isArray(cur.children)) {
            const arr = []
            cur.children.forEach(item => {
              if (hasInfo(userPermission, item.permission)) {
                arr.push(item)
              }
            })
            cur.children = arr
          }
          return pre.concat(cur)
        } else {
          return pre
        }
      }, [])
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/layout.scss';
.container {
  padding: 0px;
  &-item {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    &:hover {
      background-color:green;
    }
  }

  &-logo {
    font-size: 0;
    &> img {
      width: 100%;
    }
  }
}

.sidebar {
  list-style: none;
  margin: 0px;
  padding-inline-start: 0;
  text-align: left;
  background-color: $sidebarBgColor;
  &-item {
    list-style: none;
    &-name {
      padding-left: 20px;
      font-size: 16px;
      color: #9FA1AB;
    }
  }

  &-subitem {
    padding-left: 40px;
  }

  &>.el-menu-item,
  &>.el-submenu>.el-submenu__title {
    padding-left: 12px !important;
  }
  .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
    transform: scale(0.8)rotateZ(180deg);
  }

  .el-menu-item, .el-submenu__title {
    font-size: 16px;
    background-color: $sidebarBgColor;
    color: #9FA1AB;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    &:hover {
      color: #FFFFFF;
    }
  }

  .el-menu-item:hover,
  .el-submenu__title:hover {
    color: #FFFFFF;
  }
  .el-menu-item.is-active {
    color: #FFFFFF;
    background: $sidebarSelectColor;
  }
}
</style>
