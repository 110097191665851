<template>
  <div class="container">
    <div class="header">
      <div class="header-title">
        <div class="round"></div>
        {{ label }}：{{ count }}
      </div>

      <div class="options">
        <div
          class="options-item"
          :class="{ 'options-active': defaultValue === 7 }"
          @click="handleOptionChange(7)"
        >
          近7日
        </div>
        <div
          class="options-item"
          :class="{ 'options-active': defaultValue === 30 }"
          @click="handleOptionChange(30)"
        >
          近30日
        </div>
      </div>
    </div>

    <ve-line :data="data" :settings="chartSettings" :extend="extend"></ve-line>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      default: '',
      type: String
    },
    count: {
      default: 0,
      type: Number
    },
    data: {
      default () {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      defaultValue: 7,
      extend: {
        color: ['#FF5355'],
        xAxis: {
          boundaryGap: false
        },
        legend: {
          show: false
        },
        series: {
          type: 'line',
          areaStyle: {
            color: '#FF5355',
            opacity: 0.3,
            origin: 'start'
          },
          smooth: false
        }
      },
      chartSettings: {
        area: true
      }
    }
  },
  methods: {
    handleOptionChange (val) {
      console.log('data===', this.data)
      this.defaultValue = val
      this.$emit('optionChange', val)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
.container {
  border: 1px solid $borderColor;
  background-color: $cardColor;
  width: 100%;
  height: 460px;
}

.header {
  margin-left: 20px;
  margin-top: 20px;
  text-align: left;
  line-height: 40px;
  display: flex;
  align-items: center;

  &-title {
    color: #7d7e8e;
    font-size: 18px;
  }
}

.round {
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: #ff5355;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 3px;
}

.options {
  margin-left: 30px;
  &-item {
    display: inline-block;
    text-align: center;
    height: 24px;
    line-height: 24px;
    border: 1px solid #ff5355;
    font-size: 14px;
    min-width: 64px;
    color: white;
    background-color: #ff5355;
    cursor: pointer;
  }
  &-active {
    color: #ff5355;
    background-color: transparent;
  }
}
</style>
