<template>
  <div class="container">
    <span
      v-for="(item, index) in tabs"
      :key="item"
      class="tab"
      :class="{ 'tab-active': active === index }"
      @click="handleClick(index)"
    >
      {{ item }}
    </span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0,
      tabs: ['销售金额', '销售利润', '订单数量', '新增客户']
    }
  },
  methods: {
    handleClick (idx) {
      this.active = idx
      this.$emit('onChange', idx)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
.container {
  text-align: left;
  margin-top: 20px;
  border: 1px solid $borderColor;
  background-color: $cardColor;
  width: 100%;
  height: 70px;
  padding-left: 50px;
  box-sizing: border-box;
}

.tab {
  display: inline-block;
  line-height: 66px;
  color: #acadb7;
  font-weight: 500;
  font-size: 20px;
  margin-right: 70px;
  cursor: pointer;
  box-sizing: content-box;

  &-active {
    border-bottom: 4px solid #1d90fbff;
  }
}
</style>
