<template>
  <div class="body">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left: 30px;">
      <el-breadcrumb-item v-for="(item, index) in $route.meta" :key="index">
        <router-link v-if="item.url" :to="item.url">{{item.name}}</router-link>
        <a v-else class="link">
            {{item.name}}
        </a>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <el-dropdown>
      <div class="container">
        <!-- <i class="el-icon-setting icon"></i> -->
        <i class="iconfont icon-menu">&#xe63f;</i>
        <span class="name" @click="clickUser">{{ name }}</span>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native='handleLogout'>退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>

export default {
  data () {
    return {
      showDropdown: false
    }
  },
  computed: {
    name () {
      if (!this.$store.getters || !this.$store.getters.userInfo) {
        return ''
      }
      return this.$store.getters.userInfo.firstName
    }
  },
  methods: {
    clickUser () {
      this.showDropdown = !this.showDropdown
    },
    handleLogout () {
      this.$store.state.userInfo = null
      sessionStorage.clear()
      window.location.href = '/login'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/layout.scss';
.body{
  line-height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: auto 20px;
}

.container {
  line-height: $headerHeight;
  display: flex;
  flex-direction: row-reverse;
  padding: auto 20px;
}

.name {
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  &:hover{
    opacity: 0.5;
  }
}

.icon {
  vertical-align: middle;
  line-height: $headerHeight;
  margin-right: 10px;
}

.icon-menu {
  font-size: 32px;
  margin-right: 30px;
}

.link {
  font-size: 14px;
  font-weight: normal;
}
</style>
