<template>
  <div>
    <Tabs @onChange="handleTabChange" />

    <DataChart
      :label="label"
      :count="count"
      :data="data"
      @optionChange="handleOptionChange"
    />
  </div>
</template>

<script>
import Tabs from './tabs'
import DataChart from './dataChart'
import * as homeService from '../../api/home'
import { getDateRange } from './helper'

export default {
  components: {
    Tabs,
    DataChart
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      tabIndex: 0,
      label: '今日销售金额',
      count: 0,
      defaultvalue: 7,
      data: {}
    }
  },
  methods: {
    handleTabChange (val) {
      this.tabIndex = val
      this.fetchData(val, this.defaultvalue)
    },
    handleOptionChange (val) {
      this.defaultvalue = val
      this.fetchData(this.tabIndex, val)
    },
    async fetchData (val = this.tabIndex, date = 7) {
      const today = await homeService.todayStatistics()
      if (val === 0) {
        const res = await homeService.orderStatistics(getDateRange(date))
        this.count = this._pennyToYuan(today.sellAmount)
        this.label = '今日销售金额'
        this.data = {
          columns: ['日期', '金额'],
          rows: res.map(o => ({
            日期: o.date,
            金额: this._pennyToYuan(o.orderAmount)
          }))
        }
      }
      if (val === 1) {
        const res = await homeService.orderStatistics(getDateRange(date))
        this.count = this._pennyToYuan(today.sellFee)
        this.label = '今日销售利润'
        this.data = {
          columns: ['日期', '金额'],
          rows: res.map(o => ({
            日期: o.date,
            金额: this._pennyToYuan(o.sellFee)
          }))
        }
      }
      if (val === 2) {
        const res = await homeService.orderStatistics(getDateRange(date))
        this.count = today.sellCount
        this.label = '今日订单数量'
        this.data = {
          columns: ['日期', '数量'],
          rows: res.map(o => ({
            日期: o.date,
            数量: o.orderCount
          }))
        }
      }
      if (val === 3) {
        this.count = today.memberCount
        this.label = '今日新增客户数量'
        const res = await homeService.userStatistics(getDateRange(date))
        this.data = {
          columns: ['日期', '人数'],
          rows: res.map(o => ({
            日期: o.date,
            人数: o.userCount
          }))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
