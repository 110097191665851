import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VCharts from 'v-charts'
import VideoPlayer from 'vue-video-player'

import VueClipboard from 'vue-clipboard2'
import './assets/styles/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
import Field from './components/field.vue'
// elementui hook
import '@/elementui-hook/index.js'

import { pennyToYuan, yuanToPenny } from './utils/tools'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' })
Vue.use(VueClipboard)
Vue.use(VideoPlayer)
Vue.use(VCharts)
Vue.component('field', Field)

Vue.prototype._pennyToYuan = pennyToYuan
Vue.prototype._yuanToPenny = yuanToPenny

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
