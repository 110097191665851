import axios from '@/api/axios'
import { KJUR, b64utos } from 'jsrsasign'

export const downloadFile = async (fileRealUrl, params) => {
  try {
    const res = await axios({
      method: 'get',
      url: fileRealUrl,
      params,
      responseType: 'blob'
    })
    const blob = res.data
    const nameInfo = res.headers['content-disposition']
    const nameMatch = nameInfo.match(/filename=(\S+)/)
    const fileName = nameMatch ? nameMatch[1] : ''
    const objUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = objUrl
    link.download = fileName
    link.click()
    URL.revokeObjectURL(objUrl)
  } catch (error) {
    throw new Error('下载失败!')
  }
}

export const hasInfo = (all, cur) => {
  if (cur.length === 0) return true
  let flag = false
  for (let i = 0; i < cur.length; i++) {
    if (all.includes(cur[i])) {
      flag = true
      break
    }
  }
  return flag
}

export const getUserByDecodeJWT = (jwtString) => {
  const [, content] = jwtString.split('.')
  const uContent = b64utos(content)
  const result = KJUR.jws.JWS.readSafeJSONString(uContent)
  return result
}

export const dealError = (error) => {
  let message = ''
  if (error.title) {
    message = error.title
  }
  return message
}

export const pennyToYuan = (penny) => {
  if ((penny || penny === 0) && (Number(penny) || Number(penny) === 0)) {
    return Number((Number(penny) / 100).toFixed(2))
  } else {
    return ''
  }
}

export const yuanToPenny = (yuan) => {
  if ((yuan || yuan === 0) && (Number(yuan) || Number(yuan) === 0)) {
    return Math.round(Number(yuan) * 100).toString()
  } else {
    return ''
  }
}

export const dateFormat = (input, format = 'yyyy-MM-dd hh:mm:ss') => {
  if (typeof input === 'string') {
    if (/^\d+$/.test(input)) {
      input = parseInt(input, 10)
    }
  }
  const date = new Date(input)
  if (!date || date.toUTCString() === 'Invalid Date') {
    console.error('first param is invalid')
    return ''
  }
  var map = {
    M: date.getMonth() + 1, // 月份
    d: date.getDate(), // 日
    h: date.getHours(), // 小时
    m: date.getMinutes(), // 分
    s: date.getSeconds(), // 秒
    q: Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
    var v = map[t]
    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v
        v = v.substr(v.length - 2)
      }
      return v
    } else if (t === 'y') {
      return (date.getFullYear() + '').substr(4 - all.length)
    }
    return all
  })
  return format
}
