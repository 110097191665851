import Vue from 'vue'
import VueRouter from 'vue-router'
import * as userService from '@/api/user'
import store from '../store'
import Home from '../pages/home/index.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  // 小程序设置
  {
    path: '/applet',
    name: 'Applet',
    component: () => import('../pages/applet/edit.vue'),
    meta: [
      { name: '小程序设置' },
      { name: '首页设置' }
    ]
  },
  // 商品管理
  {
    path: '/product',
    name: 'Product',
    component: () => import('../pages/product/index.vue'),
    meta: [
      { name: '商品管理', url: '/product' },
      { name: '商品列表', url: '/product' }
    ]
  },
  {
    path: '/plate',
    name: 'Plate',
    component: () => import('../pages/plate/index.vue'),
    meta: [
      { name: '商品管理', url: '/product' },
      { name: '商品板块', url: '/plate' }
    ]
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('../pages/category/index.vue'),
    meta: [
      { name: '商品管理', url: '/product' },
      { name: '商品类目', url: '/category' }
    ]
  },
  {
    path: '/spec',
    name: 'Spec',
    component: () => import('../pages/spec/index.vue'),
    meta: [
      { name: '商品管理', url: '/product' },
      { name: '商品规格', url: '/spec' }
    ]
  },
  {
    path: '/comment',
    name: 'Comment',
    component: () => import('../pages/comment/index.vue'),
    meta: [
      { name: '商品管理', url: '/product' },
      { name: '商品评价', url: '/comment' }
    ]
  },
  // 客户管理
  {
    path: '/member',
    name: 'Member',
    component: () => import('../pages/member/index.vue'),
    meta: [
      { name: '客户管理' },
      { name: '客户列表', url: '/member' }
    ]
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('../pages/withdraw/index.vue'),
    meta: [
      { name: '客户管理' },
      { name: '提现管理', url: '/withdraw' }
    ]
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../pages/service/index.vue'),
    meta: [
      { name: '客户管理' },
      { name: '客户服务', url: '/service' }
    ]
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../pages/feedback/index.vue'),
    meta: [
      { name: '客户管理' },
      { name: '意见反馈', url: '/feedback' }
    ]
  },
  // 订单管理
  {
    path: '/order',
    name: 'Order',
    component: () => import('../pages/order/index.vue'),
    meta: [
      { name: '订单管理' },
      { name: '普通订单', url: '/order' }
    ]
  },
  {
    path: '/order/detail/:id',
    name: 'OrderDetail',
    component: () => import('../pages/order/detail.vue'),
    meta: [
      { name: '订单管理' },
      { name: '普通订单', url: '/order' },
      { name: '订单详情' }
    ]
  },
  {
    path: '/bulkOrder',
    name: 'BulkOrder',
    component: () => import('../pages/order/bulkOrders.vue'),
    meta: [
      { name: '订单管理' },
      { name: '拼团订单', url: '/bulkOrder' }
    ]
  },
  // { path: '/afterSaleOrder', name: 'AfterSaleOrder', component: () => import('../pages/order/afterSaleOrder.vue') },
  // 系统设置
  {
    path: '/basicConfig',
    name: 'basicConfig',
    component: () => import('../pages/system/basicConfig/edit.vue'),
    meta: [
      { name: '系统管理' },
      { name: '基础设置', url: '/basicConfig' }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../pages/user/index.vue'),
    meta: [
      { name: '系统管理' },
      { name: '用户管理', url: '/user' }
    ]
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('../pages/role/index.vue'),
    meta: [
      { name: '系统管理' },
      { name: '角色管理', url: '/role' }
    ]
  },
  {
    path: '/freightTemplates',
    name: 'freightTemplates',
    component: () => import('../pages/freightTemplates/index.vue'),
    meta: [
      { name: '系统管理' },
      { name: '运费模版', url: '/freightTemplates' }
    ]
  },
  {
    path: '/freightTemplates/create',
    name: 'createFreightTemplates',
    component: () => import('../pages/freightTemplates/edit.vue'),
    meta: [
      { name: '系统管理' },
      { name: '新增运费模版', url: '/freightTemplates/create' }
    ]
  },
  {
    path: '/freightTemplates/edit/:id',
    name: 'editFreightTemplates',
    component: () => import('../pages/freightTemplates/edit.vue'),
    meta: [
      { name: '系统管理' },
      { name: '编辑运费模版', url: '/freightTemplates/edit/:id' }
    ]
  },
  {
    path: '/express',
    name: 'express',
    component: () => import('../pages/expressSetting/index.vue'),
    meta: [
      { name: '系统管理' },
      { name: '物流管理', url: '/express' }
    ]
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('../pages/warehouse/index.vue'),
    meta: [
      { name: '系统管理' },
      { name: '仓库设置', url: '/warehouse' }
    ]
  },
  {
    path: '/hot',
    name: 'hot',
    component: () => import('../pages/hot/index.vue'),
    meta: [
      { name: '系统管理' },
      { name: '热门问题', url: '/hot' }
    ]
  },
  // 数据统计
  {
    path: '/good/statistics',
    name: 'goodStatistics',
    component: () => import('../pages/statistics/good/index.vue'),
    meta: [
      { name: '数据统计' },
      { name: '商品分析' }
    ]
  },
  {
    path: '/order/statistics',
    name: 'orderStatistics',
    component: () => import('../pages/statistics/order/index.vue'),
    meta: [
      { name: '数据统计' },
      { name: '订单分析' }
    ]
  },
  {
    path: '/user/statistics',
    name: 'userStatistics',
    component: () => import('../pages/statistics/user/index.vue'),
    meta: [
      { name: '数据统计' },
      { name: '用户分析' }
    ]
  },
  {
    path: '/sale/statistics',
    name: 'saleStatistics',
    component: () => import('../pages/statistics/sale/index.vue'),
    meta: [
      { name: '数据统计' },
      { name: '分销分析' }
    ]
  },
  {
    path: '/member/statistics',
    name: 'memberStatistics',
    component: () => import('../pages/statistics/member/index.vue'),
    meta: [
      { name: '数据统计' },
      { name: '合伙人分析' }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (route, _2, next) => {
  try {
    if (!store.state.userInfo) {
      const userInfo = await userService.userInfo()
      store.commit('setUserIfo', userInfo)
    }
    next()
  } catch (err) {

  }
})

export default router
